/**
 * Type: ページ
 * What: 学祭協賛申し込みフォームの確認モーダル
 */
import React from 'react';

const Confirm = ({ open, Formdata, modalClose, submit, dataPost }) => (
  <>
    <div className={`confirmation ${open ? 'open' : ''}`}>
      <div className="confirmation-inner">
        <h3 className="title is-3">内容を確認</h3>
        <div className="contactBox">
          <table className="table is-striped is-bordered is-fullwidth">
            <tbody>{Formdata}</tbody>
          </table>
          <p className="has-text-align-center mb20">
            上記の内容でよろしければ、下記個人情報の取扱いについてご同意の上、送信ボタンを押して下さい。
            <br />
            個人情報の取り扱いは
            <a
              href="https://www.softbankhawks.co.jp/company/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
            からご確認いただけます。
          </p>
          <div className="btn-group">
            <div className="buttons control">
              <button
                type="button"
                onClick={modalClose} // クリックでstateをクリアし、入力内容確認画面コンポーネントを非表示にする
                className="btn-def reverse"
              >
                <span>閉じる</span>
              </button>
              <button
                type="submit"
                className={`btn-def submit ${submit && 'spin'} `}
                onClick={dataPost}
              >
                <span>内容を送信</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Confirm;
